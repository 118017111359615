import "./App.css";
import ConstructionImage from "./Website Under Construction.png"

function App() {
  return (
    <div className="App ">
      <header className="App-header">
        <img src={ConstructionImage} alt="Under Construction" title="Construction Image" className="w-full" />
      </header>
    </div>
  );
}

export default App;
